<template>
  <div class="userInfo">
    <div class="content">
      <div class="title">
        <h3>兰州市残疾人就业创业网络服务平台 | 账号安全</h3>
        <router-link to="/">返回首页</router-link>
      </div>
      <div class="tab">
        <h1>2分钟急速创建简历，马上找到心仪工作</h1>
        <ul class="ul">
          <li class="active">
            <img src="~/static/login/01_active.png" alt="">
            <h3>基本信息</h3>
          </li>
          <li>
            <img src="~/static/login/02.png" alt="">
            <h3>工作/实习经历</h3>
          </li>
          <li>
            <img src="~/static/login/03.png" alt="">
            <h3>求职意向</h3>
          </li>
        </ul>
      </div>
      <el-form :model="form" :rules="error" label-width="120px" ref="form" class="form">
        <div class="form_title">
          <h2>个人信息</h2>
          <p>完善真实信息，招聘者可以通过这些信息搜索到您哦~</p>
        </div>
        <el-form-item label="求职状态" prop="jobStatus">
          <MulSelect
              :code="'job_invia'"
              class="w336 wrap_input"
              placeholder="请选择"
              @setMValue="setSelectValue($event,'jobStatus')"
              :defaultValue="form.jobStatus"
          ></MulSelect>
        </el-form-item>
        <el-form-item label="参加工作时间" prop="joinJobDate">
          <el-date-picker
              v-model="form.joinJobDate"
              type="month"
              value-format="yyyy/MM"
              class="w336 wrap_input"
              placeholder="请选择工作日期"
              popper-class="eagle-a11y-uncut"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所在城市" prop="addressProvinceid">
          <ThreeLink
              :codeType="'provinceid'"
              placeholder="请选择工作地点"
              class="w336 wrap_input"
              @setMValue="setScasValue($event,form,['addressProvinceid','addressCityid','addressThreeid'])"
              :defaultValue="[form.addressProvinceid,form.addressCityid,form.addressThreeid]"
          ></ThreeLink>
        </el-form-item>
        <el-form-item label="最高学历" prop="edu">
          <MulSelect
              :code="'user_edu'"
              :unlimitedEdu="true"
              class="w336 wrap_input"
              placeholder="请选择你的学历"
              @setMValue="setSelectValue($event,'edu')"
              :defaultValue="form.edu"
          ></MulSelect>
        </el-form-item>
        <el-form-item label="残疾类别/等级" required>
          <el-input v-model="disabledType" readonly class="w336 wrap_input" :title="disabledType"></el-input>
        </el-form-item>
        <el-form-item label="残疾情况描述" prop="distypeClob">
          <el-input v-model="form.distypeClob" type="textarea" rows="3" placeholder="请描述影响范围，影响程度等,如：通过助听器可以正常工作"
                    class="wrap_input wrap_input_textarea w336"></el-input>
        </el-form-item>
        <el-form-item label="个人优势" prop="description">
          <editor placeholder="请输入" class="w336" @html="editChange" :testcontent="form.description" height="400"></editor>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="w336" @click="handleSave('form')">下一步</el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
import MulSelect from "@/components/selectComponents/MulSelect";
import ThreeLink from "@/components/selectComponents/ThreeLink";
import editor from "@/components/public/editor";
export default {
  name: "userInfo",
  components: {
    MulSelect,
    ThreeLink,
    editor
  },
  data() {
    return {
      form: {
        jobStatus: '',
        joinJobDate: '',
        addressProvinceid: '',
        addressCityid: '',
        addressThreeid: '',
        edu: '',
        distypeClob:'',
        description:'',
      },
      disabledType:'',
      error: {
        jobStatus: [
          {required: true, message: "求职状态不能为空", trigger: "change"},
        ],
        joinJobDate: [
          {required: true, message: "工作时间不能为空", trigger: "change"},
        ],
        addressProvinceid: [
          {required: true, message: "工作地点不能为空", trigger: "change"},
        ],
        edu: [
          {required: true, message: "学历不能为空", trigger: "change"},
        ],
        distypeClob: [
          {required: true, message: "未填写时提示文字“请描述具体残疾情况，如影响范围，影响程度等", trigger: "blur"},
        ],
        description: [
          {required: true, message: "请输入个人优势", trigger: "change"},
        ],
      },
    }
  },
  methods: {
    //回显
   async info(){
      try {
        let res = await this.$api.getMyInfo();
        let info = res.data.data;
        this.form.jobStatus = info.jobStatus;
        this.form.joinJobDate = info.joinJobDate;
        this.form.joinJobDate = info.joinJobDate;
        this.form.addressProvinceid = info.addressProvinceid;
        this.form.addressCityid = info.addressCityid;
        this.form.addressThreeid = info.addressThreeid;
        if(info.resumeDescription){
          this.form.distypeClob = info.resumeDescription.distypeClob;
          this.form.description = info.resumeDescription.description;
        }
        this.form.edu = info.edu;
        this.getMyDisAbledInfo();
      } catch (error) {
      }
    },
    // 提交表单
    handleSave(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          let res = await this.$api.saveDisabled(this.form);
          if (res.data.success) {
            this.$message.success(res.data.msg);
            this.$router.push('/userLogin/userWork');
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(valid);
          return false;
        }
      });
    },
    async getMyDisAbledInfo() {
      let res = await this.$api.getMyDisAbledInfo();
      if (res.data.success) {
        let data = res.data.data;
        this.disabledType = data.disabledType[0].disabledType + data.disabledType[0].disabledLevel + (data.disabledType[0].disabledDetail?data.disabledType[0].disabledDetail:'');
      } else {
        console.log("错误了", error);
      }
    },
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    setScasDisTypeValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form.disabledType[key] = value;
      }
    },
    editChange(val) {
      this.form.description = val;
    },
    setSelectValue(val, key) {
      this.form[key] = val;
    },
  },
  created() {
    let info = JSON.parse(localStorage.getItem('userType'));
    if(info){
      this.info();
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="less">
.userInfo {
  width: 100%;
  min-height: 100vh;
  background: white;

  .w336 {
    width: 336px;
  }

  .content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 100px;

    .title {
      display: flex;
      padding-top: 26px;

      h3 {
        flex: 1;
        font-size: 18px;
        color: #28A46D;
      }

      a {
        font-size: 18px;
        color: #66716C;
      }

      a:hover {
        color: #28A46D;
      }
    }

    .tab {
      width: 635px;
      margin: 0 auto;
      padding-top: 60px;

      h1 {
        font-size: 28px;
        padding-bottom: 36px;
        text-align: center;
      }

      .ul {
        display: flex;

        li {
          position: relative;
          flex: 1;
          text-align: center;

          h3 {
            font-size: 18px;
            margin-top: 6px;
            color: #66716c;
          }
        }

        li.active h3 {
          color: #28A46D;
        }

        li::after {
          content: '';
          position: absolute;
          top: 50%;
          right: -50px;
          transform: translateY(-50%);
          width: 102px;
          height: 2px;
          background: #CCD0CE;
        }

        li:last-child::after {
          display: none;
        }
      }
    }

    .form {
      width: 792px;
      margin: 26px auto 0 auto;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 36px 109px;

      .form_title {
        text-align: center;
        padding-bottom: 30px;

        h2 {
          font-size: 20px;
          margin-bottom: 6px;
        }

        p {
          font-size: 16px;
          color: #66716C;
        }
      }

      /deep/ .el-input__inner::placeholder, .el-textarea__inner::placeholder {
        font-size: 14px;
        color: #999999;
      }
    }
  }
}
</style>
